import React from 'react'
import Typed from 'typed.js'

import Layout from '../components/layout'
import Button from '../components/Button'
import { Img } from '@tueri/react'

import Video from '../components/Video'

class IndexPage extends React.Component {

  // constructor(props) {
  //   super(props)
  // }

  componentDidMount() {
        const strings = [
			      'app',
            'website',
            'blog',
            'store',
            'email'
        ]
        const options = {
            strings,
            typeSpeed: 100,
            backSpeed: 50,
            backDelay: 3000,
            loop: true
        }

        this.typed = new Typed(this.el, options)
    }

    componentWillUnmount() {
        this.typed.destroy()
    }

  render() {
    return(
      <Layout home={false}>

          <div style={{ alignSelf: 'center', textAlign: 'center', margin: '100px 0' }}>
            <h1>Effortless image processing<br/>for your <span className='highlight' ref={(el) => { this.el = el }}></span></h1>
            {/* <Button link>Sign up for free</Button> */}
            <form action='https://app.tueri.io/signup'>
              <input type='email' name='email' placeholder='Email Address' required style={{ padding: '1.5rem 1.5rem 1.6rem 1.5rem', borderRadius: '4px 0 0 4px', border: '1px solid #ccc', verticalAlign: 'top' }}/>
              <Button style={{ borderRadius: '0 4px 4px 0' }}>Sign up for free</Button>
            </form>
            <div style={{ fontSize: '1.1rem', marginTop: '.5rem', color: 'rgba(0,0,0,0.6)' }}>No credit card required</div>
          </div>
          
          <div style={{ textAlign: 'center', position: 'relative' }}>

            <Video id='6Bgq-RefYzk' />
            <div style={{ position: 'absolute', left: '50%', top: '140px', transform: 'translateX(-50%)', fontWeight: 'bold', lineHeight: '24px', }}>
              <i className='material-icons' style={{ verticalAlign: '70%' }}>keyboard_arrow_left</i><span style={{ lineHeight: '24px', display: 'inline-block' }}>Comparing<br/>Before & After<br/>Tueri.io</span><i className='material-icons' style={{ verticalAlign: '70%' }}>keyboard_arrow_right</i>
            </div>

          </div>

          <div style={{ margin: '140px 0 40px 0', textAlign: 'center' }}>

            <div style={{ maxWidth: '200px', margin: '0px auto' }}><Img src='https://cdn.tueri.io/274877907168/1200px-React-icon.svg.png' alt='Tueri Image Optimization for ReactJS'/></div>

            <h2>Built for <a target='_blank' rel='noopener noreferrer' href='https://reactjs.org/'>react</a> developers like you</h2>

            <Img src='https://cdn.tueri.io/274877907169/carbon%20(2).png' alt='Comparing Site Speed with Tueri'/>

            <h3>Instant performance</h3>
            <h4>Deliver perfectly sized images for every device with real-time image processing.<br/>Search engine optimized filenames injected from alt text.</h4>

          </div>
          
      </Layout>
    )
  }
  
}

export default IndexPage