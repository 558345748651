import React from 'react'
import PropTypes from 'prop-types'

export default function Video({ id }) {

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '56.25%'
        }}>
            <iframe
                title={ id }
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
                
                src={`https://www.youtube.com/embed/${ id }?rel=0`}
                frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen 
            />
        </div>
    )
}

Video.propTypes = {
    id: PropTypes.string.isRequired
}